import { useEffect, useState } from "react"
import { addPropertyControls, ControlType } from "framer"

interface TimeBoxProps {
    label: string
    time: number
    showLabel: boolean
    countdownFont: React.CSSProperties
    labelFont: React.CSSProperties
    styleSettings: StyleSettings
}

interface StyleSettings {
    gap: number
    countdownColor: string
    labelColor: string
    backgroundColor: string
    borderThickness: number
    borderColor: string
    borderRadius: number
    boxWidth: number
    boxHeight: number
}

const TimeBox = ({
    label,
    time,
    showLabel,
    countdownFont,
    labelFont,
    styleSettings,
}: TimeBoxProps) => (
    <div
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            margin: `0 ${styleSettings.gap}px`,
            width: `${styleSettings.boxWidth}px`,
            height: `${styleSettings.boxHeight}px`,
            backgroundColor: styleSettings.backgroundColor,
            border: `${styleSettings.borderThickness}px solid`,
            borderColor: styleSettings.borderColor,
            borderRadius: `${styleSettings.borderRadius}px`,
            color: countdownFont.color,
        }}
    >
        <div style={{ ...countdownFont, color: styleSettings.countdownColor }}>
            {String(time).padStart(2, "0")}
        </div>
        {showLabel && (
            <div
                style={{
                    ...labelFont,
                    color: styleSettings.labelColor,
                    marginTop: "4px",
                }}
            >
                {label}
            </div>
        )}
    </div>
)

export default function CountdownTimer({
    targetDate,
    showLabels,
    countdownFont,
    labelFont,
    visibilitySettings,
    styleSettings,
}: {
    targetDate: Date
    showLabels: boolean
    countdownFont: React.CSSProperties
    labelFont: React.CSSProperties
    visibilitySettings: any
    styleSettings: StyleSettings
}) {
    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date()
        if (difference < 0) {
            // If the date is in the past, return zeros for all units
            return { days: 0, hours: 0, minutes: 0, seconds: 0 }
        }
        let timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        }

        return timeLeft
    }

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft())
        }, 1000)

        return () => clearTimeout(timer)
    })

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                gap: `${styleSettings.gap}px`,
            }}
        >
            <TimeBox
                label="Days"
                time={timeLeft.days}
                showLabel={showLabels}
                countdownFont={countdownFont}
                labelFont={labelFont}
                styleSettings={styleSettings}
            />
            <TimeBox
                label="Hours"
                time={timeLeft.hours}
                showLabel={showLabels}
                countdownFont={countdownFont}
                labelFont={labelFont}
                styleSettings={styleSettings}
            />
            <TimeBox
                label="Minutes"
                time={timeLeft.minutes}
                showLabel={showLabels}
                countdownFont={countdownFont}
                labelFont={labelFont}
                styleSettings={styleSettings}
            />
            <TimeBox
                label="Seconds"
                time={timeLeft.seconds}
                showLabel={showLabels}
                countdownFont={countdownFont}
                labelFont={labelFont}
                styleSettings={styleSettings}
            />
        </div>
    )
}

addPropertyControls(CountdownTimer, {
    targetDate: {
        type: ControlType.Date,
        title: "Target Date",
        defaultValue: new Date("2024-12-31"),
    },
    showLabels: {
        type: ControlType.Boolean,
        title: "Show Labels",
        defaultValue: true,
    },
    countdownFont: {
        //@ts-ignore
        type: ControlType.Font,
        title: "Countdown Font",
        controls: "extended",
        displayFontSize: true,
        displayTextAlignment: false,
        defaultFontType: "monospace",
        defaultValue: {
            fontSize: 24,
            lineHeight: "1.5em",
        },
    },
    labelFont: {
        //@ts-ignore
        type: ControlType.Font,
        title: "Label Font",
        controls: "extended",
        displayFontSize: true,
        displayTextAlignment: false,
        defaultFontType: "monospace",
        defaultValue: {
            fontSize: 14,
            lineHeight: "1.5em",
        },
    },
    styleSettings: {
        type: ControlType.Object,
        title: "Style Settings",
        controls: {
            gap: {
                type: ControlType.Number,
                defaultValue: 10,
                min: 0,
                max: 50,
                step: 1,
                unit: "px",
                title: "Gap",
            },
            countdownColor: {
                type: ControlType.Color,
                title: "Countdown Color",
                defaultValue: "#000000",
            },
            labelColor: {
                type: ControlType.Color,
                title: "Label Color",
                defaultValue: "#666666",
            },
            backgroundColor: {
                type: ControlType.Color,
                title: "Background Color",
                defaultValue: "#FFFFFF",
            },
            borderThickness: {
                type: ControlType.Number,
                title: "Border Thickness",
                defaultValue: 1,
                min: 0,
                max: 10,
                step: 1,
                unit: "px",
            },
            borderColor: {
                type: ControlType.Color,
                title: "Border Color",
                defaultValue: "#DDDDDD",
            },
            borderRadius: {
                type: ControlType.Number,
                title: "Border Radius",
                defaultValue: 8,
                min: 0,
                max: 100,
                step: 1,
                unit: "px",
            },
            boxWidth: {
                type: ControlType.Number,
                title: "Box Width",
                defaultValue: 100,
                min: 50,
                max: 300,
                step: 1,
                unit: "px",
            },
            boxHeight: {
                type: ControlType.Number,
                title: "Box Height",
                defaultValue: 100,
                min: 50,
                max: 300,
                step: 1,
                unit: "px",
            },
        },
    },
})
